import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiError, ApiProgressSubject, ApiSubject, BaseViewComponent, Environment, IApiRequest, IRequestOptions, RequestKind } from '@library/base';
import { Observable, Subscription, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ApiRequestManager {

    protected static _maxParallelRequests = 10;

    protected _disabled = false;
    private _inFlight: Set<QueueRequest<any>> = new Set();
    private _requestQueue: QueueRequest<any>[] = [];

    constructor(
        private _HttpClient: HttpClient,
        private _Environment: Environment) {
    }

    Request<Body, Result, TrackBy extends IRequestOptions<Body, any, any>>(request: IApiRequest<Body>, subject: ApiSubject<Result, TrackBy>, progressSubject?: ApiProgressSubject<TrackBy>,
                                            trackBy = {} as TrackBy, useRawURL: boolean = false, ignoreTransitionAnimations: boolean = false): () => void {
        if (this._disabled) {
            console.warn('ApiRequestManager disabled - ignoring request', request);
            return () => {};
        }

        const queueRequest = new QueueRequest({
            Request: request,
            Subject: subject,
            ProgressSubject: progressSubject,
            TrackBy: trackBy,
            UseRawURL: useRawURL,
            IgnoreTransitionAnimations: ignoreTransitionAnimations
        });

        if (this._inFlight.size < ApiRequestManager._maxParallelRequests) {
            this.RunQueueRequest(queueRequest);
        } else {
            this._requestQueue.push(queueRequest);
        }

        return () => {
            // Cancellation function
            if (this._inFlight.has(queueRequest)) {
                queueRequest.Subscription!.unsubscribe();
                this.RunNextQueued(queueRequest);
            } else {
                const index = this._requestQueue.indexOf(queueRequest);
                if (index >= 0) {
                    this._requestQueue.splice(index, 1);
                }
            }
        }
    }

    private RunNextQueued(lastRequest: QueueRequest<any>) {
        this._inFlight.delete(lastRequest);

        const nextRequest = this._requestQueue.shift();
        if (nextRequest) {
            this.RunQueueRequest(nextRequest);
        }
    }

    private RunQueueRequest<Result>(queueRequest: QueueRequest<Result>) {
        this._inFlight.add(queueRequest);

        let httpParams = queueRequest.Request.HttpParams ?? new HttpParams();
        //For the TP, we always use the raw URL because the TP has an interceptor that adds the Base URL, the or can be removed once the TP is using the new style routes everywhere
        let url = (queueRequest.UseRawURL || this._Environment.Authentication?.PrimaryToken === 'TeacherPortalAuth')
                ? queueRequest.Request.URL 
                : this._Environment.Application.BaseUrls.Api + '/' + queueRequest.Request.URL;

        // Need to duplicate the code below because of the weird way HttpClient.get/post/... use types

        if (queueRequest.ProgressSubject) {
            const httpOptions = this.HttpEventsOptions(httpParams);

            let observable: Observable<HttpEvent<Result>>;
            switch (queueRequest.Request.Kind) {
                case RequestKind.Get: observable = this._HttpClient.get<Result>(url, httpOptions); break;
                case RequestKind.Post: observable = this._HttpClient.post<Result>(url, queueRequest.Request.Body, httpOptions); break;
                case RequestKind.Patch: observable = this._HttpClient.patch<Result>(url, queueRequest.Request.Body, httpOptions); break;
                case RequestKind.Put: observable = this._HttpClient.put<Result>(url, queueRequest.Request.Body, httpOptions); break;
                case RequestKind.Delete: observable = this._HttpClient.request<Result>('delete', url, { body: queueRequest.Request.Body, ...httpOptions }); break;
            }

            queueRequest.Subscription = observable.subscribe({
                next: data => {
                    if (data.type === HttpEventType.UploadProgress || data.type === HttpEventType.DownloadProgress) {
                        this.AnimationDelay(queueRequest).subscribe(() => {
                            queueRequest.ProgressSubject!.next({ Request: queueRequest.TrackBy, Response: data });
                        });
                    } else if (data.type === HttpEventType.Response) {
                        this.RunNextQueued(queueRequest);
                        if (data.body != null) {
                            this.AnimationDelay(queueRequest).subscribe(() => {
                                queueRequest.Subject.next({ Request: queueRequest.TrackBy, Response: data.body! });
                            });
                        }
                    }
                },
                error: (error: HttpErrorResponse) => {
                    this.RunNextQueued(queueRequest);
                    queueRequest.Subject.error(new ApiError({ Request: queueRequest.TrackBy, Status: error.status, Message: error.statusText, Response: error.error }));
                }
            });
        } else {
            const httpOptions = this.HttpResponseOptions(httpParams);

            let observable: Observable<HttpResponse<Result>>;
            switch (queueRequest.Request.Kind) {
                case RequestKind.Get: observable = this._HttpClient.get<Result>(url, httpOptions); break;
                case RequestKind.Post: observable = this._HttpClient.post<Result>(url, queueRequest.Request.Body, httpOptions); break;
                case RequestKind.Patch: observable = this._HttpClient.patch<Result>(url, queueRequest.Request.Body, httpOptions); break;
                case RequestKind.Put: observable = this._HttpClient.put<Result>(url, queueRequest.Request.Body, httpOptions); break;
                case RequestKind.Delete: observable = this._HttpClient.request<Result>('delete', url, { body: queueRequest.Request.Body, ...httpOptions }); break;
            }

            queueRequest.Subscription = observable.subscribe({
                next: data => {
                    this.RunNextQueued(queueRequest);
                    this.AnimationDelay(queueRequest).subscribe(() => {
                        if (data.body != null) {
                            queueRequest.Subject.next({ Request: queueRequest.TrackBy, Response: data.body });
                        }
                    });
                },
                error: (error: HttpErrorResponse) => {
                    this.RunNextQueued(queueRequest);
                    queueRequest.Subject.error(new ApiError({ Request: queueRequest.TrackBy, Status: error.status, Message: error.statusText, Response: error.error }));
                }
            });
        }
    }

    private AnimationDelay(queueRequest: QueueRequest<any>): Observable<void> {
        return queueRequest.IgnoreTransitionAnimations ? of(1) as any : BaseViewComponent.transitionAnimationEnd;
    }

    private HttpResponseOptions(httpParams: HttpParams) {
        return {
            observe: 'response' as 'response',
            params: httpParams,
            reportProgress: false as false,
            responseType: 'json' as 'json',
            withCredentials: false as false
        };
    }

    private HttpEventsOptions(httpParams: HttpParams) {
        return {
            observe: 'events' as 'events',
            params: httpParams,
            reportProgress: true as true,
            responseType: 'json' as 'json',
            withCredentials: false as false
        };
    }

    Disable() {
        this._disabled = true;

        this._inFlight.forEach(request => request.Subscription?.unsubscribe());
        this._inFlight.clear();
        this._requestQueue = [];
    }

    get disabled(): boolean {
        return this._disabled;
    }
}

class QueueRequest<Result> {
    Request!: IApiRequest<any>;
    Subject!: ApiSubject<Result, any>;
    ProgressSubject?: ApiProgressSubject<any>;
    TrackBy!: IRequestOptions<any, any, any>;
    UseRawURL!: boolean;
    IgnoreTransitionAnimations!: boolean;
    Subscription?: Subscription;

    constructor(initializer: QueueRequest<Result>) {
        Object.assign(this, initializer);
    }
}
