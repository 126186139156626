import { AfterContentInit, Component, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { FormContainerKind, ItemWithID } from '@library/base';
import { FormFieldSelectBaseComponent } from '../form-field-select-base.component';
import { FormFieldSelectItemComponent } from '../form-field-select-item.component';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

@Component({
    selector: 'lib-input-select',
    templateUrl: './form-field-select.component.html',
    styleUrls: ['./form-field-select.component.scss']
})
export class FormFieldSelectComponent<T extends ItemWithID | string> extends FormFieldSelectBaseComponent<T, T | null> implements AfterContentInit {

    @ViewChild(MatSelect, {static: false}) select!: MatSelect;
    private _selectedItemHTML!: string;

    @Input() kindOverride: FormContainerKind = FormContainerKind.Default;
    @Output() addOptionClicked: EventEmitter<void> = new EventEmitter();

    override SortItems() {
        super.SortItems();
        requestAnimationFrame(() => {
            this.InitializeTriggerOption();
        });
        this._viewValue = this.formControl.value ? this.identifierOf(this.formControl.value) : ''
        this.viewControl.setValue(this._viewValue);
    }

    override writeValue(value: T | null) {
        if (this.identifierOf) {
            this._viewValue = value !== null ? this.identifierOf(value) : '';
            this.viewControl.setValue(this._viewValue);
            this.SetTriggerOption();
        }
    }

    ChangeEvent(event: MatSelectChange) {
        const value = this.itemQueryList.find(item => this.identifierOf(item.value) == event.value)?.value;
        this.SetFormControlValue(value ?? null);
        this.SetTriggerOption();
    }

    GetCurrentItem(value: T | null): FormFieldSelectItemComponent<T> | null {
        if (value) {
            return this.itemQueryList.find(item => item.value === value)!;
        } else {
            return null;
        }
    }

    AddOptionClicked(): void {
        this.addOptionClicked.emit();
    }

    private InitializeTriggerOption(): void {
        if(this.select?.selected) {
            this.SetTriggerOption();
        } else {
            requestAnimationFrame(() => this.InitializeTriggerOption());
        }
    }

    private SetTriggerOption(): void {
        const selectedOption = this.select?.selected as MatOption;
        if (selectedOption) {
            this._selectedItemHTML = selectedOption._getHostElement().innerHTML.replace(/<lib-hint\s+[^>]*>.*?<\/lib-hint>/gs, "");
        } else {
            this._selectedItemHTML = "";
        }
    }

    get selectedItemHTML(): string {
        return this._selectedItemHTML;
    }
}
