<ng-container [ngSwitch]="renderContext?.RenderMode">

    <ng-container *ngSwitchCase="WebsiteRenderMode.Website">
        <lib-website-page
            [ID]="currentViewablePageID"
            [mode]="WebsiteMode.Live"
            [websiteData]="website"
            [headerLogoID]="website.HeaderLogoMediaLibraryItemID!"
            (navigationPageClicked)="NavigationPageClicked($event)">
            <ng-template #textMediaBlockHeader let-block>
                <span [innerHTML]="block.Header | trustHtml"></span>
            </ng-template>
            <ng-template #textMediaBlockBody let-block>
                <span [innerHTML]="block.Body | trustHtml"></span>
            </ng-template>
            <ng-template #textMediaBlockImage let-imageItem let-blockType="blockType">
                <a class="d-flex h-100" *ngIf="(imageItem && imageItem.Hyperlink)" 
                    [href]="imageItem.Hyperlink" 
                    [target]="imageItem.OpenInNewTab ? '_blank' : '_self'">
                    <ng-container *ngTemplateOutlet="img; context: {$implicit: imageItem, blockType: blockType, showCursorPointer: true}"></ng-container>
                </a>
                <ng-container  *ngIf="!(imageItem && imageItem.Hyperlink)">
                    <ng-container *ngTemplateOutlet="img; context: {$implicit: imageItem, blockType: blockType, showCursorPointer: false}"></ng-container>
                </ng-container>
            </ng-template>
            <ng-template #textMediaBlockImageHeader let-block>
                    <span [innerHTML]="block.Header | trustHtml"></span>
            </ng-template>
            <ng-template #textMediaBlockImageBody let-block>
                <span [innerHTML]="block.Body | trustHtml"></span>
            </ng-template>
            <ng-template #htmlBlock let-block>
                <lib-iframe class="w-100" [minHeight]="100" [src]="block.Body!"></lib-iframe>
            </ng-template>
            <div copyright>
                <p class="scss-copyright-typography" i18n="@@WPWebsiteFooterCopyright">© {{currentYear}} {{website.Title}} Powered by <a class="scss-copyright-typography" [href]="hostname" target="_blank">#Product Name#</a></p>
            </div>
        </lib-website-page>
    </ng-container>

    <ng-container *ngSwitchCase="WebsiteRenderMode.Widget">
        <ng-container *ngIf="renderContext!.SchoolActive">
            <lib-website-block
                [data]="websiteBlock"
                [renderContext]="renderContext!">
            </lib-website-block>
        </ng-container>
        <ng-container *ngIf="!renderContext!.SchoolActive">
            <lib-card>
                <div class="d-flex flex-column justify-content-center align-items-center gap-4 mx-0 mx-lg-2 my-4">
                    <img src="assets/Widget-Signup-Form-Exclamation-Circle.svg">
                    <h6 class="typography-semibold text-center" i18n="@@WPSignupFormInactiveSchoolMessage">This #schooltypename# account is no longer active. Please log into your #productname# account and update your membership settings to reactivate this account.</h6>
                </div>
            </lib-card>
        </ng-container>
    </ng-container>

</ng-container>

<ng-template #img let-imageItem let-blockType="blockType" let-showCursorPointer="showCursorPointer">
    <lib-image
        [offsetYPercent]="imageItem.OffsetYPercent"
        [offsetXPercent]="imageItem.OffsetXPercent"
        [imageScale]="imageItem.ImageScale"
        [showCursorPointer]="showCursorPointer"
        [alt]="(imageItem && imageItem.AltText) ? imageItem.AltText : ''" 
        [src]="(imageItem && imageItem.Url) ? (imageItem.Url | scaleImageUrl:blockType) : 'assets/images/Website Block Text And Media - Placeholder - Square.svg'">
    </lib-image>
</ng-template>
