import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { IconModule } from '@library/icon';
import { LocalizationModule } from '@library/localization';
import { FormFieldCheckboxComponent } from './checkbox/form-field-checkbox.component';
import { ErrorComponent } from './error/error.component';
import { FormFieldBaseComponent } from './form-field-base.component';
import { HintComponent } from './hint/hint.component';
import { FormFieldCurrencyComponent } from './input/currency/form-field-currency.component';
import { FormFieldDecimalComponent } from './input/decimal/form-field-decimal.component';
import { FormFieldInputBaseComponent } from './input/form-field-input-base.component';
import { LabelComponent } from './label/label.component';

import { MatDividerModule } from '@angular/material/divider';
import { MatRadioModule } from '@angular/material/radio';
import { ColorPickerModule } from '@iplab/ngx-color-picker';
import { BaseModule } from '@library/base';
import { ButtonModule } from '@library/button';
import { UtilityModule } from '@library/utility';
import { MbscDatepickerModule } from '@mobiscroll/angular';
import { FormFieldCheckboxGroupItemComponent } from './checkbox-group/form-field-checkbox-group-item.component';
import { FormFieldCheckboxGroupComponent } from './checkbox-group/form-field-checkbox-group.component';
import { FormFieldInputContainerComponent } from './container/form-field-container.component';
import { AutocompletePositionDirective } from './input/autocomplete-position.directive';
import { FormFieldChipsComponent } from './input/chips/form-field-input-chips.component';
import { ColorPickerComponent } from './input/color-picker/color-picker.component';
import { FormFieldMonthYearComponent } from './input/date/month-year/form-field-month-year.component';
import { FormFieldDateRangeComponent } from './input/date/range/form-field-date-range.component';
import { DateSelectorComponent } from './input/date/selector/date-selector.component';
import { FormFieldDateComponent } from './input/date/single/form-field-date.component';
import { FormFieldNumberComponent } from './input/number/form-field-number.component';
import { FormFieldSecretComponent } from './input/password/form-field-password.component';
import { FormFieldPercentComponent } from './input/percent/form-field-percent.component';
import { FormFieldSelectAutoCompleteComponent } from './input/select/auto-complete/form-field-select-auto-complete.component';
import { FormFieldSelectChipsComponent } from './input/select/chips/form-field-select-chips.component';
import { FormFieldSelectItemGroupComponent } from './input/select/form-field-select-item-group.component';
import { FormFieldSelectItemComponent } from './input/select/form-field-select-item.component';
import { FormFieldSelectComponent } from './input/select/single/form-field-select.component';
import { MatOptionHeightDirective } from './input/select/single/mat-option-height.directive';
import { FormFieldTextAreaComponent } from './input/text-area/form-field-text-area.component';
import { FormFieldTextComponent } from './input/text/form-field-text.component';
import { FormFieldRadioGroupItemComponent } from './radio-group/form-field-radio-group-item.component';
import { FormFieldRadioGroupComponent } from './radio-group/form-field-radio-group.component';
import { ReadOnlyComponent } from './read-only/read-only.component';

import { OverlayModule } from '@angular/cdk/overlay';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BannerModule } from '@library/banner';
import { SplitCheckboxComponent } from './checkbox/split-checkbox/split-checkbox.component';
import { FormFieldBillingProfileComponent } from './input/billing-profile/form-field-billing-profile.component';
import { FormFieldDateBaseComponent } from './input/date/form-field-date-base.component';
import { DateRangeSelectorComponent } from './input/date/range-selector/date-range-selector.component';
import { FormFieldInvoiceNumberFormatComponent } from './input/invoice-number-format/form-field-invoice-number-format.component';
import { FormFieldRecurrencePickerComponent } from './input/recurrence-picker/form-field-recurrence-picker.component';
import { ChipPreviewComponent } from './input/select/chips/chip-preview/form-field-chip-preview.component';
import { MatSelectTruncateDirective } from './input/select/single/mat-select-truncate.directive';
import { FormFieldTaxComponent } from './input/tax/tax.component';
import { FormFieldDurationComponent } from './input/time/duration/form-field-duration.component';
import { FormFieldTimeComponent } from './input/time/form-field-time.component';




@NgModule({
    declarations: [
        AutocompletePositionDirective,
        FormFieldBaseComponent,
        FormFieldInputBaseComponent,
        FormFieldInputContainerComponent,
        FormFieldCheckboxComponent,
        FormFieldCheckboxGroupComponent,
        FormFieldCheckboxGroupItemComponent,
        FormFieldChipsComponent,
        FormFieldNumberComponent,
        FormFieldDecimalComponent,
        FormFieldCurrencyComponent,
        FormFieldTextComponent,
        FormFieldTextAreaComponent,
        FormFieldDateComponent,
        FormFieldDateRangeComponent,
        FormFieldMonthYearComponent,
        FormFieldRadioGroupComponent,
        FormFieldRadioGroupItemComponent,
        FormFieldSelectItemComponent,
        FormFieldSelectItemGroupComponent,
        FormFieldSelectComponent,
        FormFieldSelectChipsComponent,
        FormFieldSelectAutoCompleteComponent,
        FormFieldSecretComponent,
        FormFieldTaxComponent,
        ColorPickerComponent,
        DateSelectorComponent,
        ErrorComponent,
        HintComponent,
        LabelComponent,
        ReadOnlyComponent,
        FormFieldPercentComponent,
        MatOptionHeightDirective,
        FormFieldDurationComponent,
        FormFieldTimeComponent,
        ChipPreviewComponent,
        FormFieldRecurrencePickerComponent,
        FormFieldBillingProfileComponent,
        MatSelectTruncateDirective,
        FormFieldInvoiceNumberFormatComponent,
        DateRangeSelectorComponent,
        FormFieldDateBaseComponent,
        SplitCheckboxComponent
    ],
    imports: [
        CommonModule,
        IconModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        MatInputModule,
        MatRadioModule,
        MatSelectModule,
        MatChipsModule,
        MatMenuModule,
        MatAutocompleteModule,
        MatDividerModule,
        LocalizationModule,
        MbscDatepickerModule,
        ButtonModule,
        ColorPickerModule,
        BaseModule,
        UtilityModule,
        OverlayModule,
        BannerModule,
        MatTooltipModule
    ],
    exports: [
        AutocompletePositionDirective,
        FormFieldInputContainerComponent,
        FormFieldCheckboxComponent,
        FormFieldCheckboxGroupComponent,
        FormFieldCheckboxGroupItemComponent,
        FormFieldChipsComponent,
        FormFieldNumberComponent,
        FormFieldDecimalComponent,
        FormFieldCurrencyComponent,
        FormFieldTextComponent,
        FormFieldTextAreaComponent,
        FormFieldDateComponent,
        FormFieldDateRangeComponent,
        FormFieldDurationComponent,
        FormFieldMonthYearComponent,
        FormFieldRadioGroupComponent,
        FormFieldRadioGroupItemComponent,
        FormFieldSelectItemComponent,
        FormFieldSelectItemGroupComponent,
        FormFieldSelectComponent,
        FormFieldSelectChipsComponent,
        FormFieldSelectAutoCompleteComponent,
        FormFieldSecretComponent,
        FormFieldTaxComponent,
        ColorPickerComponent,
        DateSelectorComponent,
        ErrorComponent,
        HintComponent,
        LabelComponent,
        FormFieldPercentComponent,
        MatOptionHeightDirective,
        MatSelectTruncateDirective,
        FormFieldRecurrencePickerComponent,
        FormFieldInvoiceNumberFormatComponent,
        FormFieldTimeComponent,
        FormFieldRecurrencePickerComponent,
        DateRangeSelectorComponent,
        FormFieldRecurrencePickerComponent,
        FormFieldBaseComponent,
        FormFieldInputBaseComponent,
        FormFieldDateBaseComponent,
        FormFieldBillingProfileComponent,
        SplitCheckboxComponent
    ]
})
export class FormFieldModule { }
