import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogBaseViewComponent, FormBase, ProgressDialogData, SimpleDialogData } from '@library/base';
import { UploadDialogComponent, UploadDialogData, UploadingDialogComponent, UploadingDialogData } from '@library/upload';
import { DialogConfirmComponent } from './confirm/dialog-confirm.component';
import { DialogDeleteComponent } from './delete/dialog-delete.component';
import { DialogIFrameComponent } from './iframe/dialog-iframe.component';
import { DialogInfoComponent } from './info/dialog-info.component';
import { DialogProgressComponent } from './progress/dialog-progress.component';
import { DialogRecurringDeleteComponent } from './recurring-delete/dialog-recurring-delete.component';
import { _batchJoin } from './batch-join';

//For Reference
//https://blog.angular-university.io/angular-material-dialog/

type Extract<T> = T extends DialogBaseViewComponent<infer V> ? V : never;

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    private _dialogRef!: MatDialogRef<any>;
    // private _dialogContentComponentFactory;
    // private readonly cssClassDialogFullscreen = 'dialog-fullscreen';
    // private readonly cssClassDialogNotFullScreen = 'dialog-not-fullscreen';
    constructor(private dialog: MatDialog) {
        _batchJoin.DialogService = this;
    }

    //=== Basic 5 Dialog Types ============================================================
    CreateDeleteDialog(data: SimpleDialogData): DialogDeleteComponent {
        if(data == null || data.DismissPreviousDialog) {
            this.DismissDialog(); //close any currently open dialogs by default, unless specified
        }

        if (!data.PrimaryButtonText) {
            data.PrimaryButtonText = $localize`:@@CommonDeleteButton:Delete`;
        }
        if (!data.SecondaryButtonText) {
            data.SecondaryButtonText = $localize`:@@CommonCancelButton:Cancel`;
        }

        return this.CreateDialog(DialogDeleteComponent, data, false);
    }

    CreateConfirmDialog(data: SimpleDialogData, isDismissable: boolean = false): DialogConfirmComponent {
        if(data == null || data.DismissPreviousDialog) {
            this.DismissDialog(); //close any currently open dialogs by default, unless specified
        }

        if (!data.PrimaryButtonText) {
            data.PrimaryButtonText = $localize`:@@CommonOKButton:OK`;
        }
        if (!data.SecondaryButtonText) {
            data.SecondaryButtonText = $localize`:@@CommonCancelButton:Cancel`;
        }

        return this.CreateDialog(DialogConfirmComponent, data, isDismissable);
    }

    CreateInfoDialog(data: SimpleDialogData, isDismissable: boolean = true): DialogInfoComponent {
        if(data == null || data.DismissPreviousDialog) {
            this.DismissDialog(); //close any currently open dialogs by default, unless specified
        }

        if (!data.PrimaryButtonText) {
            data.PrimaryButtonText = $localize`:@@CommonClose:Close`;
        }

        return this.CreateDialog(DialogInfoComponent, data, isDismissable);
    }

    CreateProgressDialog(data: ProgressDialogData): DialogProgressComponent {
        if (data?.DismissPreviousDialog) {
            this.DismissDialog(); //close any currently open dialogs by default, unless specified
        }

        return this.CreateDialog(DialogProgressComponent, data, false);
    }

    CreateIFrameDialog(data: SimpleDialogData, isDismissable: boolean = true): DialogIFrameComponent {
        if(data == null || data.DismissPreviousDialog) {
            this.DismissDialog(); //close any currently open dialogs by default, unless specified
        }

        if (!data.PrimaryButtonText) {
            data.PrimaryButtonText = $localize`:@@CommonClose:Close`;
        }

        return this.CreateDialog(DialogIFrameComponent, data, isDismissable);
    }

    CreateRecurringDeleteDialog(data: SimpleDialogData): DialogRecurringDeleteComponent {
        if(data == null || data.DismissPreviousDialog) {
            this.DismissDialog(); //close any currently open dialogs by default, unless specified
        }

        if (!data.PrimaryButtonText) {
            data.PrimaryButtonText = $localize`:@@CommonDeleteButton:Delete`;
        }
        if (!data.SecondaryButtonText) {
            data.SecondaryButtonText = $localize`:@@CommonCancelButton:Cancel`;
        }
        if (!data.Text) {
            data.Text = $localize`:@@CommonDeleteRecurringText:Are you sure you want to delete this <b>recurring</b> entry?`;
        }
        if (!data.PrimaryOptionText) {
            data.PrimaryOptionText = $localize`:@@CommonOnlyThisEntry:Only this entry`;
        }
        if (!data.SecondaryOptionText) {
            data.SecondaryOptionText = $localize`:@@CommonThisEntryAndAllFutureEntries:This entry and all future entries`;
        }

        return this.CreateDialog(DialogRecurringDeleteComponent, data, false);
    }

    //=== Upload Dialogs ==================================================================
    CreateUploadDialog(data: UploadDialogData): UploadDialogComponent {
        if(data == null || data.DismissPreviousDialog) {
            this.DismissDialog(); //close any currently open dialogs by default, unless specified
        }

        if (!data.PrimaryButtonText) {
            data.PrimaryButtonText = $localize`:@@CommonOKButton:OK`;
        }
        if (!data.SecondaryButtonText) {
            data.SecondaryButtonText = $localize`:@@CommonCancelButton:Cancel`;
        }

        return this.CreateDialog(UploadDialogComponent, data, false);
    }

    CreateUploadingDialog(data: UploadingDialogData): UploadingDialogComponent {
        this.DismissDialog(); //close any currently open dialogs

        if (!data.PrimaryButtonText) {
            data.PrimaryButtonText = $localize`:@@CommonOKButton:OK`;
        }
        if (!data.SecondaryButtonText) {
            data.SecondaryButtonText = $localize`:@@CommonCancelButton:Cancel`;
        }

        return this.CreateDialog(UploadingDialogComponent, data, false);
    }

    //=== Custom Dialog ===================================================================
    CreateCustomDialog<T extends DialogBaseViewComponent<Extract<T>>>(
        component: ComponentType<T>,
        dataToInject?: Extract<T>,
        isDismissable: boolean = false,
        dismissAnyPreviousDialog: boolean = true
    ): T {
        if(dismissAnyPreviousDialog) {
            this.DismissDialog(); //close any currently open dialogs by default, unless specified
        }

        return this.CreateDialog(component, dataToInject, isDismissable);
    }

    //=====================================================================================
    private CreateDialog<T extends DialogBaseViewComponent<Extract<T>>>(
        dialogComponentReference: ComponentType<T>,
        dataToInject?: Extract<T>,
        isDismissable = false,
        dialogPanelClass: string = 'dialog-panel-class'
    ): T {
        FormBase.ignoreCreatedForms = true;

        const dialogRef = this.dialog.open(dialogComponentReference, {
            panelClass: dialogPanelClass,
            data: dataToInject,
            autoFocus: false,
            restoreFocus: false,
            disableClose: !isDismissable
        });

        dialogRef.keydownEvents().subscribe((e) => {
            if (e.key === 'Escape' && !this._dialogRef.disableClose) {
                this.DismissDialog();
            }

            let element = e.target as HTMLElement;
            if (
                e.key === 'Enter' &&
                !dialogRef.componentInstance.primaryActionClicked &&
                !dialogRef.componentInstance.secondaryActionClicked &&
                element.nodeName !== 'TEXTAREA' && element.nodeName !== 'INPUT'
            ) {
                dialogRef.componentInstance.PrimaryAction();
            }
        });

        this._dialogRef = dialogRef;
        return this._dialogRef.componentInstance;
    }

    get dialogReference() {
        return this._dialogRef;
    }

    DismissDialog(): void {
        this._dialogRef?.close();
    }
}
